export const pricingData = [
    {
      id: 1,
      type: 'Static',
      features: ['3 Page Website',"Logo & company profile",'Hosting & Domain (.com)', 'SSL Certificate', '5 Business Emails', 'Mobile Responsive Design', 'Accounting Software (Zoho Basic)', 'Website Maintenance Service', 'Social Media Integration', 'One revision', 'Renewal Fee: AED500/Year', 'An Administration Fee of AED 100 plus 5% VAT will be applicable.'],
      bgcolor: "#FF9B48",
      pricing:"FREE",
      starting:"---"
    },
    {
      id: 2,
      type: 'Dynamic',
      features: ['5 Page Website','Custom Website Design',"Logo & company profile", 'Hosting & Domain (.com)', 'SSL Certificate', '10 Business Emails', 'SEO Optimization', 'Dynamic & Mobile Responsive Design', 'Accounting Software (Zoho Basic)', 'Website Maintenance Service', 'Social Media Integration','3 revisions', 'Renewal Fee: AED750/Year', 'An Administration Fee of AED 100 plus 5% VAT will be applicable.'],
      bgcolor: "#FF0000",
      pricing:"AED 950",
      starting:"Starting from"
    },
    {
      id: 3,
      type: 'Ecommerce',
      features: ['E-Commerce Website', 'Advanced Custom Website Design',"Logo & company profile", 'Hosting & Domain (.com)', 'SSL Certificate', '20 Business Emails', 'Dynamic & Mobile Responsive Design', 'Content Management System', 'Payment Gateway Integration', 'Accounting Software (Zoho Basic)', 'Website Maintenance Service', 'Social Media Integration','5 revisions', 'Renewal Fee: AED1000/Year', 'An Administration Fee of AED 100 plus 5% VAT will be applicable.'],
      bgcolor: "#924CFF",
      pricing:"AED 1950",
      starting:"Starting from"
    }
  ];