import AiConsultation from '../../Assets/solutions/AiBased/AiConsultation.png'
import AiIntegration from '../../Assets/solutions/AiBased/AiIntegration.png'
import AiMobileDevelopment from '../../Assets/solutions/AiBased/AiMobileDevelopment.png'
import LLM from '../../Assets/solutions/AiBased/LLM.png'
import Nlp from '../../Assets/solutions/AiBased/Nlp.png'
import Chatbot from '../../Assets/solutions/AiBased/Chatbot.png'

const Aibased = [
    {
        img: AiIntegration,
        title: "AI Integration",
        alt:"AI Integration",
        descrition: "Transform your operations with our seamless AI integration services! We utilize advanced tools like TensorFlow and PyTorch to embed AI solutions into your existing systems, automating processes and driving data-driven decisions for enhanced productivity."

    },
    {
        img: AiMobileDevelopment,
        title: "AI Model Development",
        alt:"AI Model Development",
        descrition: "Unlock actionable insights with our bespoke AI model development. Our expert team leverages advanced frameworks to create customized models that address your unique challenges, from predictive analytics to personalized recommendations."
    },
   
    
   
    {
        img: LLM,
        title: "LLM Development",
        alt:"LLM Development",
        descrition: "Harness the power of Large Language Models (LLMs) with our specialized development services. We utilize state-of-the-art tools and techniques to develop models that understand and generate human-like text, enhancing communication and engagement."
    },
    {
        img: Nlp,
        title: "NLP Development",
        alt:"NLP Development",
        descrition: "Revolutionize data interaction with our Natural Language Processing (NLP) solutions. we build intelligent systems that comprehend human language, enabling sentiment analysis and automated communication for improved user experiences."

    },
    {
        img: Chatbot,
        title: "Chatbot Development",
        alt:"Chatbot Development",
        descrition: "Boost customer engagement with our dynamic chat development services! Our custom chatbots, built with tools like Dialogflow and Rasa, deliver real-time assistance and understand user intent, ensuring seamless interactions that enhance satisfaction and loyalty."

    },
    {
        img: AiConsultation,
        title: "AI Consultation",
        alt:"AI Consultation",
        descrition: "Navigate the AI landscape with our expert consultation services. Our team utilizes proven frameworks to develop a strategic roadmap tailored to your business goals, empowering you to leverage AI technologies effectively for sustainable growth."

    },
]

export default Aibased;