import React, { useState, useEffect } from 'react';
import { marked } from 'marked';


const PrivacyPolicy = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    // Configure marked options
    // marked.setOptions({
    //   breaks: true,
    //   gfm: true,
    //   headerIds: true
    // });

    // // Create custom renderer
    // const renderer = new marked.Renderer();
    
    // // Customize link rendering
    // renderer.link = (href, title, text) => {
    //   return `<a href="${href}" title="${title || ''}" target="_blank" rel="noopener noreferrer" class="text-blue-600 underline hover:text-blue-800">${text}</a>`;
    // };

    // // Customize heading rendering
    // renderer.heading = (text, level) => {
    //   const sizes = {
    //     1: 'text-4xl font-bold mb-6',
    //     2: 'text-3xl font-semibold mb-4 mt-8',
    //     3: 'text-2xl font-medium mb-3 mt-6',
    //     4: 'text-xl font-medium mb-2 mt-4'
    //   };
    //   return `<h${level} class="${sizes[level]}">${text}</h${level}>`;
    // };

    // // // Customize list rendering
    // renderer.list = (body, ordered) => {
    //   const type = ordered ? 'ol' : 'ul';
    //   const classList = ordered 
    //     ? 'list-decimal pl-6 my-4 space-y-2' 
    //     : 'list-disc pl-6 my-4 space-y-2';
    //   return `<${type} class="${classList}">${body}</${type}>`;
    // };

    // // Customize list item rendering
    // renderer.listitem = (text) => {
    //   return `<li class="text-gray-700">${text}</li>`;
    // };

    // // Customize paragraph rendering
    // renderer.paragraph = (text) => {
    //   return `<p class="text-gray-700 mb-4 leading-relaxed">${text}</p>`;
    // };

    // // Set the custom renderer
    // marked.setOptions({ renderer });

    const markdownContent = `
# Privacy Policy

Last Updated: October 30, 2024

# **Introduction**

Welcome to Dynamic Leo. We are committed to protecting your privacy and ensuring the security of your personal information in accordance **_with UAE_** [**_Federal Decree Law No. 45_**](https://www.uaelegislation.gov.ae/en/legislations/1972/download) **_of 2021 on Personal Data Protection and other applicable UAE regulations_**. This privacy policy explains how we collect, use, disclose, and safeguard your information across our comprehensive range of digital services.

# **Compliance with UAE Data Protection Laws**

Dynamic Leo operates in full compliance with:

* UAE Federal [Decree Law No. 45](https://www.uaelegislation.gov.ae/en/legislations/1972/download) of 2021 on Personal Data Protection
* Dubai International Financial Centre (DIFC) [Data Protection Law No. 5 of 2020](https://www.difc.ae/business/laws-and-regulations/legal-database/difc-laws/data-protection-law-difc-law-no-5-2020)
* [Abu Dhabi Global Market (ADGM) Data Protection Regulations 2021](https://adgmen.thomsonreuters.com/sites/default/files/net_file_store/ADGM1547_23167_VER2021.pdf)
* [Telecommunications and Digital Government Regulatory Authority (TDRA) guidelines](https://tdra.gov.ae/en/About/tdra-sectors/telecommunication/regulatory-affairs-department/regulations-and-ruling)

# **Information We Collect**

## **Digital Marketing Services**

- Client business information and analytics
- Website visitor behavior and demographics
- Marketing campaign performance data
- Social media engagement metrics
- Email marketing subscriber information
- Customer feedback and survey responses  

## **Lead Generation Data**

- Contact information (name, email, phone number)
- Business requirements and preferences
- Industry-specific data points
- Inquiry source and touchpoint data
- Lead scoring and qualification metrics
- Communication history and preferences

We process lead generation data in accordance with [UAE Federal Decree Law No. 45 of 2021](https://www.uaelegislation.gov.ae/en/legislations/1972/download) and maintain strict protocols for:

- Lead verification and validation
- Consent management and documentation
- Data enrichment and segmentation
- Lead distribution and assignment
- Follow-up communication tracking
- Lead lifecycle management

**Your lead information may be:**

- Stored in our secure CRM system
- Used for personalized communication
- Analyzed for service optimization
- Shared with relevant internal teams
- Retained according to our data retention policy
- Protected with advanced security measures

## **Website Development Services**

- User authentication credentials
- Website usage statistics
- Form submission data
- Cookie preferences
- Technical logs and analytics
- Server-side processing information

## **AI/ML Services**

- Training data sets
- Model performance metrics
- User interaction logs
- Algorithm feedback data
- Testing and validation data
- Service usage patterns

## **Mobile App Development**

- Device information
- App usage statistics
- User preferences
- Location data (when permitted)
- Push notification tokens
- In-app behavior analytics

## **Domain/Hosting Services**

- Domain registration information
- DNS records
- Server logs
- Bandwidth usage data
- Security certificates
- Hosting configuration details

## **Software Maintenance**

- System logs
- Error reports
- Update histories
- User support tickets
- Performance metrics
- Security audit logs  

# **Data Protection Measures**

## **Security Infrastructure**

- Advanced encryption protocols (AES-256)
- Multi-factor authentication (MFA)
- Regular security audits
- Firewall protection
- Intrusion detection systems
- 24/7 monitoring

## **Data Storage and Processing**

- Regular backup procedures
- Data redundancy systems
- Access control mechanisms
- Data classification protocols
- Disaster recovery planning

1. **Your Rights Under UAE Law**

As per [UAE Federal Decree Law No. 45 of 2021](https://www.uaelegislation.gov.ae/en/legislations/1972/download), you have the right to:

- Access your personal data
- Request corrections to inaccurate data
- Withdraw consent for data processing
- Request data deletion
- Object to direct marketing
- Receive data in a portable format
- File complaints with relevant authorities

## **Data Retention and Deletion**

We retain your personal data for:

- Active service period plus 5 years for business records
- Legal compliance requirements
- Regulatory audit purposes
- Service improvement analysis
- Security incident investigation
- Statistical analysis (anonymized)

## **Third-Party Service Providers**

We may share data with:

- Cloud service providers
- Payment processors
- Analytics services
- Marketing platforms
- Customer support systems
- Security service providers

All third-party providers must comply with:

- UAE data protection regulations
- Our data processing agreements
- Industry security standards
- Privacy protection requirements  

# **International Data Transfers**

When transferring data internationally, we ensure:

- Compliance with UAE cross-border data transfer regulations
- Adequate data protection measures in recipient countries
- Appropriate safeguards and contractual obligations
- Regular monitoring of transfer mechanisms
- Documentation of transfer basis

# **Cookie Policy**

We use cookies for:

- Essential website functionality
- Performance monitoring
- User experience improvement
- Analytics and statistics
- Marketing optimization
- Security enhancement

# **Children's Privacy**

We do not knowingly collect data from children under 18 without parental consent, in compliance with UAE regulations on protecting minors' data.

# **Security Incident Response**

In case of a data breach, we will:

- Notify affected users within 72 hours
- Report to relevant UAE authorities
- Implement containment measures
- Conduct thorough investigations
- Take corrective actions
- Update security protocols

# **Updates to Privacy Policy**

We reserve the right to update this privacy policy. Changes will be:

- Posted on our website
- Notified to active users
- Effective immediately upon posting
- Archived for reference
- Compliant with UAE regulations

# **Contact Information**

For privacy-related inquiries:

- Email: [privacy@dynamicleo.ae](mailto:privacy@dynamicleo.ae)
- Phone: +971509677514
- Address: Office 21, First Floor, UNIBRICKS Business Center, Salah Al Din Street, Dubai UAE
- Data Protection Officer: Mr. Qais Abbas

# **Regulatory Compliance**

We maintain compliance with:

- [UAE Consumer Protection Law](https://u.ae/en/information-and-services/justice-safety-and-the-law/consumer-protection)
- [Electronic Transactions and Commerce Law](https://u.ae/en/about-the-uae/digital-uae/regulatory-framework/electronic-transactions-and-trust-services-law)
- [Cybercrime Law](https://u.ae/en/information-and-services/justice-safety-and-the-law/cyber-safety-and-digital-security)
- [TDRA regulations](https://tdra.gov.ae/en/About/tdra-sectors/telecommunication/regulatory-affairs-department/regulations-and-ruling)
- [Industry-specific standards of software development by the government of UAE](https://usuaebusiness.org/wp-content/uploads/2017/02/Standards-and-Technical-Regulations-in-the-UAE-Final1.pdf)

This privacy policy demonstrates our commitment to protecting your data while providing innovative digital solutions. For specific inquiries about your data or this policy, please contact our Data Protection Officer.
`;

    setContent(marked(markdownContent));
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 list-disc">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-md p-8">
          <div 
            className="markdown-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;