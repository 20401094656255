import SEO from "../../Assets/solutions/DigitalMarketing/SEO.jpg";
import Analytics from "../../Assets/solutions/DigitalMarketing/Analytics.jpg";
import ContentMarketing from "../../Assets/solutions/DigitalMarketing/ContentMarketing.jpg";
import PPC from "../../Assets/solutions/DigitalMarketing/PPC.jpg";
import EmailMarketing from "../../Assets/solutions/DigitalMarketing/EmailMarketing.jpg";
import SocialMedia from "../../Assets/solutions/DigitalMarketing/SocialMedia.jpg";

const DMWork = [
  {
    img: SEO,
    title: "Search Engine Optimization (SEO)",
    alt: "Search Engine Optimization (SEO)",
    descrition:"Unlock your website's full potential with our expert SEO services We'll boost your rankings with in-depth audits, smart keyword strategies, and powerful competitor insights.",
  },
  {
    img: SocialMedia,
    title: "Social Media Marketing and Advertisement",
    alt: "Social Media Marketing",
    descrition:"Engage and grow your audience with our social media marketing and advertising solutions. Our dedicated team of experts craft tailored campaigns that resonate with your target demographics..",
    platforms: "Meta Ads Manager, Linkedin Marketing, Tiktok Marketing",
  },
  {
    img: ContentMarketing,
    title: "Content Marketing",
    alt: "Content Marketing",
    descrition:"Let us help you grow your business with personalized digital marketing strategies that connect with your audience. From social media presence to paid advertising, we ensure your brand gets the attention and results it deserves.",
  },
]
const DMWork2 = [

  {
    img: EmailMarketing,
    title: "Email Marketing",
    alt: "Email Marketing",
    descrition:"Connect with your audience through email campaigns that feel personal and deliver real value. We craft engaging, targeted emails that build relationships and drive results for your business.",
  },
  {
    img: PPC,
    title: "Pay-Per-Click (PPC) Advertising",
    alt: "PPC",
    descrition:"Maximize your ROI with targeted PPC campaigns that drive immediate traffic and conversions. We create data-driven ads that reach the right audience, ensuring every click counts.",
  },
  {
    img: Analytics,
    title: "Analytics and Reporting",
    alt: "Analytics and Reporting",
    descrition:"Gain clear insights into your performance with our detailed analytics and reporting services. We turn data into actionable strategies, helping you track progress, optimize campaigns, and achieve measurable results.",
  },
];

const digitalMarketingWorks = { DMWork, DMWork2 };

export default digitalMarketingWorks;
