import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaPhoneAlt, FaMapMarkerAlt, FaWhatsapp } from "react-icons/fa";
import axios from "axios";
import bg from "../../Assets/Hero/contact.jfif";

function Content() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contactNo: "",
    message: "",
    services: [],
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (service) => {
    setFormData((prevState) => {
      const services = prevState.services.includes(service)
        ? prevState.services.filter((s) => s !== service)
        : [...prevState.services, service];
      return { ...prevState, services };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        "https://api.dynamicleoecommerce.com/api/contact_form",
        formData,
        { withCredentials: true }
      );
      setIsSubmitting(false);
      setSuccessMessage("Your message has been sent successfully!");
      setFormData({
        firstName: "",
        lastName: "",
        contactNo: "",
        message: "",
        services: [],
      });
    } catch (error) {
      console.error("Error sending contact details:", error.message);
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="w-full py-8 h-full relative overflow-hidden"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="absolute inset-0"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.24)" }}
      />
      <h2
        style={{ fontSize: "clamp(26px,3vw,50px)" }}
        className="text-black md:text-center drop-shadow-lg uppercase font-bold px-[1rem] md:px-[2rem]"
      >
        Contact our team
      </h2>
      <p
        className="text-black md:text-center font-medium mx-auto drop-shadow-md px-[1rem] md:px-[2rem] py-2 md:w-[80%] xl:w-[60%] md:tracking-wider"
        style={{ fontSize: "clamp(17px,1.1vw,22px)" }}
      >
        Got any questions about products or scaling on our platform? We’re here
        to help. Chat with our friendly team 24/7 and get onboard in less than 5
        minutes.
      </p>
      <div className="relative w-full sectionlayout mt-16 md:space-x-8 z-10 flex flex-col-reverse md:flex-row">
        <div
          className="w-full md:w-1/2 mt-8 flex justify-center"
          data-aos="fade-up"
        >
          <div className="w-full bg-white p-8 rounded-xl shadow-xl">
            <form className="mt-6 space-y-6" onSubmit={handleSubmit}>
              <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
                <div className="w-full sm:w-1/2">
                  <label
                    htmlFor="firstName"
                    className="block text-[#666666] text-[18px] font-semibold mb-2"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    className="w-full p-3 border border-gray-300 text-[#666666] text-[14px] rounded-md focus:outline-none focus:border-gray-500"
                    required
                  />
                </div>
                <div className="w-full sm:w-1/2">
                  <label
                    htmlFor="lastName"
                    className="block text-[#666666] text-[18px] font-semibold mb-2"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    className="w-full p-3 border border-gray-300 text-[#666666] text-[14px] rounded-md focus:outline-none focus:border-gray-500"
                    required
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="contactNo"
                  className="block text-[#666666] text-[18px] font-semibold mb-2"
                >
                  Contact No
                </label>
                <input
                  type="text"
                  id="contactNo"
                  name="contactNo"
                  value={formData.contactNo}
                  onChange={handleChange}
                  placeholder="Contact No"
                  className="w-full p-3 border border-gray-300 text-[#666666] text-[14px] rounded-md focus:outline-none focus:border-gray-500"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="message"
                  className="block text-[#666666] text-[18px] font-semibold mb-2"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                  rows="4"
                  className="w-full p-3 border border-gray-300 text-[#666666] text-[14px] rounded-md focus:outline-none focus:border-gray-500"
                  required
                />
              </div>

              <div className="space-y-2">
                <p className="text-[#666666] pb-3 font-bold">Services</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 font-[600] text-[16px] gap-4">
                  {[
                    "Digital Marketing",
                    "Web Development",
                    "Mobile App Dev",
                    "AI-based Solutions",
                    "Domain & Hosting",
                    "Maintenance",
                  ].map((service) => (
                    <label key={service} className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={formData.services.includes(service)}
                        onChange={() => handleCheckboxChange(service)}
                      />
                      {service}
                    </label>
                  ))}
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="transition-all flex justify-center items-end duration-300 ease-in-out hover:text-[#B38E5D] text-white mx-auto py-3 px-5 w-full rounded-[10px] border-2 border-[#B38E5D] hover:bg-transparent bg-[#B38E5D]"
              >
                {isSubmitting ? "Sending..." : "Send Message"}
              </button>
              {successMessage && (
                <p className="text-green-500 mt-4 text-center">
                  {successMessage}
                </p>
              )}
            </form>
          </div>
        </div>

        {/* Contact Info Section */}
        <div className="w-full md:w-1/2 py-6 md:pl-20 flex flex-col space-y-8 text-[#1C1C1C] drop-shadow-md">
          <ContactInfo />
        </div>
      </div>
    </div>
  );
}

const ContactInfo = () => (
  <>
    <div data-aos="fade-left">
      <h3 className="text-2xl font-bold">Call us</h3>
      <div className="flex font-semibold items-center mt-2">
        <FaPhoneAlt className="mr-2 text-[1.1rem]" />
        <p>
          <a href="tel:+971509677514">+971 50 967 7514</a>
        </p>
      </div>
    </div>
    <div data-aos="fade-left">
      <h3 className="text-2xl font-bold">WhatsApp us</h3>
      <div className="flex font-semibold items-center mt-2">
        <FaWhatsapp className="mr-2 text-[1.4rem]" />
        <p>
          <a
            href="https://wa.me/971521205514"
            target="_blank"
            rel="noopener noreferrer"
          >
            +971 52 120 5514
          </a>
        </p>
      </div>
    </div>
    <div data-aos="fade-left">
      <h3 className="text-2xl font-bold">Visit us</h3>
      <div className="flex font-semibold items-center mt-2">
        <FaMapMarkerAlt className="mr-2 text-[1.3rem]" />
        <p>Dubai, UAE</p>
      </div>
    </div>
  </>
);

export default Content;
