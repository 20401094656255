import DomainNameRegistration from '../../Assets/solutions/DomainHosting/DomainNameRegistration.png'
import DNSManagemnt from '../../Assets/solutions/DomainHosting/DNSManagemnt.png'
import NLP from '../../Assets/solutions/DomainHosting/NLP.png'
import DomainTransfer from '../../Assets/solutions/DomainHosting/DomainTransfer.png';
import SSL from '../../Assets/solutions/DomainHosting/SSL.png';
import EmailHosting from '../../Assets/solutions/DomainHosting/EmailHosting.png';

const DomainandHosting = [
    {
        img: DomainNameRegistration,
        title: "Domain name Registration",
        alt:"Domain name Registration",
        descrition: "Secure your unique online identity with our hassle-free domain registration services. We provide a wide selection of domain extensions to help you establish your brand presence."

    },
    {
        img: DNSManagemnt,
        title: "Web Hosting",
        alt:"Web Hosting",
        descrition: "Our reliable web hosting solutions offer fast loading times and excellent uptime, ensuring your website remains accessible to visitors around the clock. Choose from shared, VPS, and dedicated hosting options tailored to your needs."

    },
   
    {
        img: DomainTransfer,
        title: "Website Migration",
        alt:"Website Migration",
        descrition: "Our seamless website migration service ensures a smooth transition of your existing site to our hosting platform without downtime. We handle the technical aspects, so you can focus on your business."
    },
    {
        img: SSL,
        title: "SSL Certificate Integration",
        alt:"SSL Certificate Integration",
        descrition: "Enhance your website’s security with our SSL certificate installation services, ensuring safe data transmission for your users. This boosts your credibility and improves your site's SEO ranking."

    },
    {
        img: EmailHosting,
        title: "Email Hosting",
        alt:"Email Hosting",
        descrition: "Professional email hosting services give your business a polished image with custom email addresses using your domain. Enjoy features like advanced security, spam protection, and ample storage space."

    },
    {
        img: NLP,
        title: "24/7 Customer Support",
        alt:"24/7 Customer Support",
        descrition: "Our dedicated support team is available 24/7 to assist you with any domain or hosting inquiries. Whether you need technical help or guidance, we’re here to ensure your experience is smooth and hassle-free."
    }
]

export default DomainandHosting;